<template>
  <div>
    <div class="row" ref="counter_cont">
      <div class="counter-card text-center col-12 col-sm-3">
        <div style="height: 60px">
          <img src="/static/images/1ikonaCounter.svg" data-w="img1"/>
        </div>
        <div class="counter-num" ref="parcels_cnt">&nbsp;</div>
        <div class="counter-label">
          <span v-html="t.counter_1"></span>
        </div>
        <div ref="anchor"></div>
      </div>
      <div class="counter-card text-center col-12 col-sm-6">
        <div style="height: 60px">
          <img src="/static/images/2ikonaCounter.svg" data-w="img2"/>
        </div>
        <div class="counter-num" ref="p_m2">&nbsp;</div>
        <div class="counter-label">
          <span v-html="t.counter_2"></span>
        </div>
      </div>
      <div class="counter-card text-center col-12 col-sm-3">
        <div style="height: 60px">
          <img src="/static/images/3ikonaCounter.svg" data-w="img3"/>
        </div>
        <div class="counter-num" ref="coowners_cnt">&nbsp;</div>
        <div class="counter-label">
          <span v-html="t.counter_3"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiFetchGetNoAuth} from "@/api/handler"

  const t =
    //<i18n>
{
  "en": {
    "counter_1": "LAND PARCELS HITS",
    "counter_2": "HECTARES OF LAND DISCOVERED",
    "counter_3": "CO-OWNERSHIP RECORDS IDENTIFIED"
  },
  "hr": {
    "counter_1": "POGOĐENIH PARCELA",
    "counter_2": "HEKTARA OTKRIVENIH ZEMLJIŠTA",
    "counter_3": "PREPOZNATIH SUVLASNIČKIH ZAPISA"
  },
  "es": {
    "counter_1": "ÉXITOS DE PARCELAS BUSCADAS",
    "counter_2": "PIES CUADRADOS DE TIERRA DESCUBIERTA",
    "counter_3": "REGISTROS DE COPROPRIETARIOS IDENTIFICADOS"
  }
}
//</i18n>

  import "intersection-observer";

  import {TweenMax, TweenLite, Linear, EaselPlugin} from "gsap/all";

  import * as d3 from "d3";

  export default {
    name: "HomeCounter",
    data() {
      return {
        t: t[this.$lang]
      };
    },
    mounted() {
      const vm = this;

      const animation = vm.Animation();

      // apiFetchGetNoAuth("/api/get-summary")
      //   .then(response => response.json())
      //   .then(animation.create)

      animation.create({
        parcels_cnt: 3012031,
        p_m2: 672655*10000,
        coowners_cnt: 9075290,
      })

    },
    methods: {
      Animation() {
        const vm = this;
        let counter_data, animation_ready, animation_triggered;
        initial()

        function initial() {

          TweenMax.set(vm.$refs.counter_cont, {
            y: 50,
            alpha: 0
          });

          const io_options = {
            rootMargin: "0px",
            threshold: 0
          };

          const io_callback = entries => {
            if (!!entries[0].isIntersecting) {
              animate();
              io_observer.disconnect();
            }
          };

          let io_observer = new IntersectionObserver(io_callback, io_options);
          io_observer.observe(vm.$refs.anchor);
        }

        function create(data) {
          data["p_m2"] /= 10000;

          counter_data = Object.keys(data).map(k => ({
            id: k,
            cnt: Math.floor(data[k])
          }));

          animation_ready = true;
          if (animation_triggered) animate();
        }

        function numHandler(num) {
          return Math.floor(num)
            .toLocaleString("en-US")
            .replace(/,/g, " ");
        }

        function animate() {
          animation_triggered = true
          if (!animation_ready) return

          TweenMax.to(vm.$refs.counter_cont, 1, {
            y: 0,
            alpha: 1
          });

          counter_data.forEach((d, i) => {
            const el = vm.$refs[d.id];

            d3.transition("counter_up_" + i)
              .duration(7000)
              .delay(200 * i)
              .ease(d3.easeLinear)
              .tween("", () => {
                const i = d3.interpolate(0, d.cnt);
                const LazyOut = (break_point, last_count) => (t, i) =>
                  t < break_point
                    ? i(t / break_point)
                    : i(1) - last_count / ((t - break_point) * 10);
                const lazyOut = LazyOut(0.6, 5);
                return t => {
                  const v = lazyOut(t, i);
                  try {
                    el.textContent = numHandler(v);
                  } catch (e) {
                  }
                };
              });
          })
        }

        return {
          create
        }
      }
    }
  };
</script>

<style scoped>

  [data-w="img1"] {
    position: relative;
    height: 100%;
  }

  [data-w="img2"] {
    position: relative;
    height: 81%;
  }

  [data-w="img3"] {
    top: 10%;
    position: relative;
    height: 80%;
  }

  .counter-num {
    font-size: 40px;
    font-weight: 800;
    width: 110%;
    position: relative;
    left: -5%;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 120%;
  }

  .counter-label {
    max-width: 170px;
    margin: auto;
    font-size: 16px;
  }

  @media (max-width: 576px) {

    .counter-card {
      margin-top: 30px;
    }
  }
</style>
